export function getTagColor(tag) {
    if(tag === "CesiumJs") {
        return "#FF0000";
    }
    else if(tag === "Node.js") {
        return "#59BFFF";
    }
    else {
        return "#EAB700"
    }
}

